<template>
    <div class="PurchaseOrderEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            ref="editForm"
            :rules="rules"
            size="small"
            label-width="80px"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构">
                            <el-input v-model="formExtend.deptName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="配送中心">
                            <el-input readonly v-model="formExtend.repoName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="formExtend.creator" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="formExtend.createTime" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input v-model="search" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加</el-button
                >
                <el-button type="primary" @click="showPickUpGoods" size="small" :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息</el-button
                >
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    class="mainTb"
                    border
                    stripe
                    :data="form.detailParams"
                    show-summary
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="50" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="120">
                        <template slot-scope="scope">
                            <span v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}<br /></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品类型" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).type | skuTypeDesc }}
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="60">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="采购价" min-width="100" prop="showPrices" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                ></ef-price-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <template v-if="deptEnabledBigUnit">
                        <el-table-column label="包装因子" min-width="80" prop="packFactors">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="采购箱数" min-width="120" prop="bigUnitCounts">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="120" prop="bulkCounts">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        :max="scope.row.packFactors - 1"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="赠品数量" min-width="80" prop="giftCounts">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.giftCounts'"
                                :rules="rules.giftCounts"
                            >
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    @change="computeThenChangeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="采购数" min-width="100" prop="counts">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <template v-if="deptEnabledBigUnit">
                                    <ef-stock-biz-count-input v-model="scope.row.counts" :disabled="true" />
                                </template>
                                <template v-else>
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.counts"
                                        @change="computeThenChangeCountAndMoney(scope.row)"
                                    />
                                </template>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="采购金额" min-width="100" prop="purchaseShowMoney" v-if="showInPrice()">
                        <template slot-scope="scope">
                            <span style="font-weight: bolder">{{ scope.row.purchaseShowMoney | m }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="总计数量" width="100" prop="totalCounts">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <span style="font-weight: bolder">{{ scope.row.totalCounts }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="生产日期" min-width="150">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.produceTimes'"
                                :rules="rules.produceTimes"
                            >
                                <el-date-picker
                                    v-model="scope.row.produceTimes"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    size="mini"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" fixed="right">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>
<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import PickUpGoods from 'components/PickUpGoods';
import StockBizCommon, { packFactor, PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
} from 'js/validate/ValidateCommonRule';
import {
    countGreaterThanZeroValidateRule,
    countGreaterThanEqualZeroValidateRule,
    countValidateRule,
} from 'js/validate/ValidateStockBizRule';
import GoodsCommon from 'js/GoodsCommon';
import EfPriceInput from 'components/EfPriceInput';

export default {
    name: 'PurchaseOrderEdit',
    components: { EfPriceInput, PickUpGoods, EfStockBizCountInput },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            deptEnabledBigUnit: false,
            repoEnabledBigUnit: false,
            formExtend: {
                createTime: '',
                creator: '',
                deptName: '',
                repoName: '',
            },
            form: {
                deptCode: '',
                repoCode: '',
                detailParams: [],
                delCodes: [],
            },
            detailParamPropertyArrForSubmit: [
                'codes',
                'goodsCode',
                'counts',
                'packFactors',
                'bigUnitCounts',
                'bulkCounts',
                'giftCounts',
                'produceTimes',
                new PropertyConverter('showPrices', 'prices', (price) => {
                    return MoneyUtils.moneyToDb(price);
                }),
            ],
            goodsDetailMap: new Map(),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            rules: {
                totalCounts: [countGreaterThanZeroValidateRule()],
                produceTimes: { required: true, message: '请选择生产日期', trigger: ['blur'] },
                giftCounts: countGreaterThanEqualZeroValidateRule(),
                deptCode: deptCodeValidateRule(),
                repoCode: deptCodeValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                    countGreaterThanZeroValidateRule(),
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
            },
        };
    },
    mounted() {
        (async () => {
            //查询数据
            const rst = await this.$efApi.purchaseOrderApi.detail(this.code);
            Util.copyProperties(rst, this.form);
            Util.copyProperties(rst, this.formExtend);
            //是否启用大单位权限
            this.deptEnabledBigUnit = await this.hasBigUnitPrivilege(this.form.deptCode);
            this.repoEnabledBigUnit = await this.hasBigUnitPrivilege(this.form.repoCode);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                rst,
                this.detailParamPropertyArrForShow()
            );
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
        })();
    },
    computed: {
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.repoCode) || Util.isEmpty(this.search);
        },
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.repoCode);
        },
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.repoCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        hasBigUnitPrivilege(deptCode) {
            return this.$efApi.deptPrivilegeApi.hasDeptPrivilege(deptCode, 'dept.biz.goods.allow.bigUnit');
        },
        detailParamPropertyArrForShow() {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('produceTimes', 'produceTimes', (produceTimes) => {
                    return this.formatDate(new Date(produceTimes));
                }),
                new PropertyConverter('prices', 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
            if (this.deptEnabledBigUnit) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        //已保存的数据中的包装因子直接展示，不考虑配送中心是否开启大单位权限
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'purchaseShowMoney', (purchaseShowMoney, detailParam) => {
                    return this.computeShowMoney(detailParam);
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(this.computeCount(detailParam)).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        detailParamPropertyArrForAdd() {
            const rst = [
                'goodsCode',
                new PropertyConverter(null, 'codes'),
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(null, 'giftCounts', () => {
                    //赠品初始值设置为0
                    return 0;
                }),
                new PropertyConverter(null, 'purchaseShowMoney'),
                new PropertyConverter(wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
            ];
            if (this.deptEnabledBigUnit) {
                rst.push(
                    new PropertyConverter(null, 'bigUnitCounts'),
                    new PropertyConverter(null, 'bulkCounts', () => {
                        return 0;
                    }),
                    new PropertyConverter(packFactor, 'packFactors', (packFactor) => {
                        //包装因子为空或者配送中心未启用大单位权限，则设置为1
                        return Util.isEmpty(packFactor) || !this.repoEnabledBigUnit ? 1 : packFactor;
                    })
                );
            }
            return rst;
        },
        computeThenChangeCountAndMoney(row) {
            //计算数量和金额
            row.counts = this.computeCount(row);
            row.purchaseShowMoney = this.computeShowMoney(row);
            row.totalCounts = this.decimal(row.counts)
                .add(row.giftCounts || 0)
                .toNumber();
        },
        computeThenChangeMoney(row) {
            row.showMoney = this.computeMoney(row);
        },
        computeCount(detailParam) {
            let counts;
            if (this.deptEnabledBigUnit) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            return this.decimal(detailParam.counts || 0)
                .mul(detailParam.showPrices || 0)
                .toNumber();
        },
        showPickUpGoods() {
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), this.formExtend.repoName);
        },
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        pickUpGoodsQueryParam() {
            return { deptCode: this.form.repoCode, goodsStatusIn: [1] };
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                //将商品详情列表转换成多字段数组形式
                const updateParam = {
                    ...this.form,
                    ...StockBizCommon.convertStockBizDetailParamsToParamArrObj(
                        this.form.detailParams,
                        this.detailParamPropertyArrForSubmit
                    ),
                };
                //删除非提交必要字段数据
                delete updateParam.detailParams;
                await this.$efApi.purchaseOrderApi.updateByFormData(this.code, updateParam);
                this.goBackAndReload();
            });
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd()
            );
            if (oldDetails.length <= 150) {
                this.form.detailParams = oldDetails;
                this.$refs.pickUpGoods.dialogFormVisible = false;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(money) {
            return MoneyUtils.formatMoney(money);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
.PurchaseOrderEdit .tb .el-form-item {
    margin: 0;
}
</style>
